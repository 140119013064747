import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27')
];

export const server_loads = [2,3,4,5,6];

export const dictionary = {
		"/(root)": [~8,[2]],
		"/(root)/admin": [~11,[2,3]],
		"/(root)/admin/exercises": [~12,[2,3]],
		"/(root)/admin/prompt/[promptId]": [~13,[2,3]],
		"/api/v1/callback/google": [~21],
		"/auth": [~22,[7]],
		"/auth/intermidiate": [~26,[7]],
		"/auth/logout": [~27,[7]],
		"/auth/[type]/google": [~23,[7]],
		"/auth/[type]/login": [~24,[7]],
		"/auth/[type]/register": [~25,[7]],
		"/(root)/s": [~14,[2,4]],
		"/(root)/s/[homeworkId]/[exerciseId]": [~15,[2,4]],
		"/(root)/t": [16,[2,5]],
		"/(root)/t/[userId]": [~17,[2,5,6]],
		"/(root)/t/[userId]/homework/[homeworkId]": [~18,[2,5,6]],
		"/(root)/t/[userId]/homework/[homeworkId]/exercise": [~19],
		"/(root)/t/[userId]/lessons": [~20,[2,5,6]],
		"/(root)/[homeworkId]/[exerciseId]": [~9,[2]],
		"/(root)/[homeworkId]/[exerciseId]/stats": [~10,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';